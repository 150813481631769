export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Organization',
    route: 'organization',
    icon: 'UsersIcon',
  },
  {
    title: 'Servers',
    route: 'servers',
    icon: 'UsersIcon',
  },

]
